<template>
    <div class="portal_pages">
        <div class="result_wpr new">
            <div class="actions mt-2 mb-2">
                <h3 class="section_title">
                    Workout Library
                    <div class="categories_list" @click="filterList = !filterList" v-click-outside="closeFilters">
                        {{selectedCategory}}<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                            <ul>
                                <li @click="selectedCategory = 'All workouts'">All workouts</li>
                                <li v-for="(category, c) of workoutCategories" @click="selectedCategory = category.name; params.category = category.id;" :key="c">{{ category.name }}</li>
                            </ul>
                        </div>
                    </div>
                </h3>
                <ul>
                    <li class="optionDrops search_area m-0" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" :disabled="assetsLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="workouts.total" class="list_container">
            <ul class="client_productList">
                <li v-for="(workout, p) of workouts.data" :key="p">
                    <div class="product_card">
                        <img v-if="workout.thumb" :src="workout.thumb" alt="" class="product_thumb">
                        <img v-else src="@/assets/images/thumb/default-progress.svg" alt="" class="product_thumb">
                        <div class="product_cont">
                            <h4 class="pointer">{{ workout.name }}</h4>
                            <p>{{ workout.description && workout.description.length > 150 ? workout.description.substr(0, 150)+'...' : workout.description }}</p>
                            <div class="action-buttons">
                                <a href="javascript:void(0);" class="primary_btn custom_element">Preview</a>
                                <a href="javascript:void(0);" class="primary_btn custom_element">Start</a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="pagination mt-2">
                <pagination v-model="params.page" :pages="workouts.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="empty_section" v-else>
            <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
            <h1 class="text-center">
                There are no assigned workouts for you right now. Have a chat with your coach about becoming a client to kickstart your fitness journey!
            </h1>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Client Portal Workouts',

        data () {
            return {
                searchField: false,
                params: {
                    per_page: 8,
                    page: 1,
                    is_private: 0,
                    search: '',
                    category: 0,
                },
                isTyping: false,
                filterList: false,
                selectedCategory: 'All workouts',
            };
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            'params.category' (val) {
                const vm = this;

                vm.getWorkouts(vm.params);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getWorkouts(vm.params);
                        }

                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getWorkouts(vm.params);
                        }
                    }
                }
            },
        },

        computed: mapState({
            workouts: state => state.clientPortalModule.workouts,
            assetsLoader: state => state.clientPortalModule.assetsLoader,
            workoutCategories: state => state.workoutModule.workoutCategories,
        }),

        mounted () {
            const vm = this;

            if (!vm.workouts.total) {
                vm.getWorkouts(vm.params);
            } else {
                vm.params.page = vm.workouts.current_page;
            }

            vm.getWorkoutCategories({ is_workout_plan: 0 });
        },

        methods: {
            ...mapActions({
                getWorkouts: 'clientPortalModule/getWorkouts',
                getWorkoutCategories: 'workoutModule/getWorkoutCategories',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getWorkouts(vm.params);
            },

            filterPublicPrivatePage (isPrivate) {
                const vm = this;

                vm.params.page = 1;
                vm.params.is_private = isPrivate;
                vm.getWorkouts(vm.params);
            },

            closeFilters () {
                const vm = this;

                vm.filterList = false;
            },
        },
    }
</script>

<style scoped>
    h3.section_title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #121525;
        margin: 0;
        align-items: center;
    }
    .portal_pages{
        margin-bottom: 40px;
    }
    .portal_pages .client_productList {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 20px -10px;
    }

    .portal_pages .client_productList li {
        flex: 0 0 25%;
        padding: 15px 10px;
    }

    .portal_pages .client_productList .product_card {
        width: 100%;
    }

    .client_productList .product_card .product_cont p{
        margin-bottom: 45px;
    }

    .inner_pages .actions .filter_btn {
        padding: 0;
        display: flex;
        gap: 30px;
    }

    .inner_pages .actions .filter_btn .btn_item {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .inner_pages .actions .filter_btn .btn_item.active {
        color: #121525;
        font-weight: 500;
    }
    .empty_section {
        /* border: 1px solid #D9D9D9; */
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        margin: 20px 0;
    }
    .empty_section img{
        max-height: 90px;
        width: auto;
        margin-bottom: 30px;
    }
    .empty_section h1 {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        color: #2c3e50;
        /* font-style: italic; */
    }
    .result_wpr.new .actions > ul{
        gap: 0;
        height: auto;
    }
    .result_wpr.new .actions > ul li.search_area{
        background: #fff;
        height: 35px;
        margin-left: auto;
        margin-right: 30px;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text]{
        font-size: 11px !important;
    }
    .result_wpr.new .actions > ul li.search_area.active input[type=text]{
        width: 250px;
        padding: 0 15px;
    }
    .action-buttons{
        width: calc(100% + 10px);
        display: flex;
        margin: auto -5px 0;
    }
    .categories_list{
        padding: 5px 15px;
        border-radius: 15px;
        background: #e9e9e9;
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        position: relative;
        min-width: 100px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .categories_list i{
        margin-left: auto;
        padding-left: 10px;

    }
    .categories_list .dropdown_wpr{
        min-width: 170px;
    }
    .categories_list .dropdown_wpr::before{
        display: none;
    }
    @media(max-width: 1199px){
        .result_wpr.new .actions > ul{
            height: 51px;
        }
        .portal_pages .client_productList .product_card .product_cont{
            padding: 15px;
        }
        .portal_pages .client_productList .product_card .product_cont h4{
            font-size: 14px;
            line-height: 19px;
        }
        .portal_pages .client_productList .product_card .product_cont p{
            font-size: 12px;
            line-height: 16px;
        }
        .inner_pages .actions .filter_btn{
            gap: 20px;
        }
        .inner_pages .actions .filter_btn .btn_item{
            font-size: 13px;
        }
        .portal_pages .client_productList li{
            flex: 0 0 33.333%;
        }
    }
    @media(max-width: 767px){
        .portal_pages .client_productList{
            padding: 0;
        }
        .portal_pages .client_productList li{
            flex: 0 0 50%;
        }
        /* .result_wpr.new .actions > ul{
            padding-top: 10px;
        } */
        .result_wpr.new .actions > ul li.search_area{
            height: 32px;
            border-radius: 17px !important;
            margin-right: 20px;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 200px;
        }
        .categories_list .dropdown_wpr{
            left: auto;
            right: 1px;
        }
    }
    @media(max-width: 650px){
        .result_wpr.new .actions > ul{
            width: 100%;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
        }
        h3.section_title{
            width: 100%;
            margin-bottom: 20px;
        }
    }
    @media(max-width: 499px){
        .result_wpr.new .actions > ul{
            height: auto;
        }
        .inner_pages .actions .filter_btn{
            gap: 20px;
        }
        .inner_pages .actions .filter_btn .btn_item{
            font-size: 13px;
            line-height: 18px;
        }
        .result_wpr.new .actions > ul li.search_area.active input[type=text]{
            width: 180px;
        }
        .empty_section h1{
            font-size: 13px;
            line-height: 23px;
        }
    }
    @media(max-width: 450px){
        .list_container{
            max-width: 300px;
            margin: 0 auto;
        }
        .portal_pages .client_productList li{
            flex: 0 0 100%;
        }
        .result_wpr.new .actions{
            margin-top: 0;
            margin-bottom: 25px;
        }
        .result_wpr.new .actions > ul{
            column-gap: 7px;
            row-gap: 10px;
            padding-top: 0;
        }
        .result_wpr.new .actions > ul li.search_area{
            width: 100%;
            order: -1;
            margin: 5px 0;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text], .result_wpr.new .actions > ul li.search_area.active input[type=text]{
            width: 100%;
            padding: 0 0 0 15px;
        }
    }
</style>
